/* eslint-disable quotes */
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import {getNewApiBaseUrl} from 'components/common/helpers'

import { LeftNav, HtmlDocumentFrame } from 'components/business-components';
import './products-page.scss';
import config from 'components/config';
import { useTranslation } from 'react-i18next';

const apiBaseUrl = `${getNewApiBaseUrl(config.apiBase)}/documents/`

const BASEPATH = '/products';

export default ({ content }) => {
  const { t } = useTranslation();

  const htmlUrl = useMemo(() => {
    if (window.location.pathname === (BASEPATH + '/aisp')) {
      return apiBaseUrl + 'aisp-html';
    } else {
      return apiBaseUrl + 'pisp-html';
    }
  }, [window.location.pathname]);

  return (
    <Layout>
      <Helmet title={content?.frontmatter?.title} />
      <div className="products-page">
        <div className="left">
          <LeftNav
            navData={[
              {
                label: t('nav.products'), icon: 'fa fa-list-alt', subItems: [
                  { label: t('nav.accountsApi'), url: '/products/aisp' },
                  { label: t('nav.paymentsApi'), url: '/products/pisp' }
                ]
              }
            ]}
          />
        </div>
        <div className="right document">
          <HtmlDocumentFrame src={htmlUrl} />
        </div>
      </div>
    </Layout>
  )
}