/* eslint-disable no-useless-escape */
/* eslint-disable global-require */
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby';
import { authUrl, logout } from 'components/lib/auth';
import userIcon from '../../assets/icons/user-icon.svg';
import { useTranslation } from 'react-i18next';
import LanguageMenu from './languageMenu';
import config from 'components/config';

const userName = (user) => {
  if (user?.userType === 'fintech-developer') {
    return 'Developer';
  } else if (user?.userType === 'fintech-admin') {
    return 'Admin';
  } else {
    return 'OBAF';
  }
};

const isActive = ({ isCurrent }) =>
  isCurrent ? { className: 'active' } : null;

export default ({
  user,
  loggedIn,
  className
}) => {
  const { t } = useTranslation();
  const [dropDownOn, setDropDownOn] = useState(false);
  const [state, setState] = useState(() => authUrl());
  const dropDownRef = useRef();

  useEffect(() => {
    document.addEventListener('click', handleClick);

    function handleClick(e) {
      if (dropDownRef && dropDownRef.current) {
        const ref = dropDownRef.current;

        if (!ref.contains(e.target)) {
          setDropDownOn(false);
        }
      }
    }

    const url = window.location.href?.trim?.();
    const domain = url?.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)

    setState(authUrl(domain[0]));

    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <>
      <header id="header" className={className || null}>
        <div className="content">
          <Link to="/" className="logo">
            {config.logoUrl ? (
              <>
                <Helmet link={[{ rel: 'icon', type: 'image/svg', href: config.logoUrl }]}> </Helmet>
                <figure className="header-logo-wrapper">
                  <img
                    // src={require(`../../assets/images/tenant/${isSubdomain?.toLowerCase()}.svg`)}
                    src={config.logoUrl}
                    alt={config.companyName}
                  />
                </figure>
              </>
            ) : (
              <div className="header-logo-text">
                <p>{userName(user)}</p>
                <div className="header-logo-text-dot" />
              </div>
            )}
          </Link>

          {loggedIn ? (
            <nav>
              <Link
                to="/doc/user-guide"
                getProps={isActive}
                className="navbar-item"
              >
                <span>{t('common.developer')}</span>
              </Link>
              <Link to="/user/dashboard" className="navbar-item">
                <span>{t('pages.contact.dashboard')}</span>
              </Link>
              <Link to="/contact" className="navbar-item">
                <span>{t('pages.contact.contact')}</span>
              </Link>
              <div className="splitter" />
              <div className="user-icon-holder" ref={dropDownRef}>
                <figure
                  className="user-icon"
                  onClick={() => setDropDownOn((prevState) => !prevState)}
                >
                  <img src={userIcon} alt="User" />
                </figure>
                {dropDownOn ? (
                  <div className="user-header-dropdown">
                    <Link
                      to="/user/profile"
                      key="profile"
                      className="navbar-item"
                    >
                      <span>{t('pages.profile.title')}</span>
                    </Link>
                    <Link
                      to="/logout"
                      key="logout"
                      onClick={logout}
                      className="navbar-item"
                    >
                      <span>{t('pages.profile.logOut')}</span>
                    </Link>
                  </div>
                ) : null}
              </div>
            </nav>
          ) : (
            <nav>
              <Link
                to="/doc/user-guide"
                getProps={isActive}
                className="navbar-item"
              >
                <span>{t('common.developer')}</span>
              </Link>
              {/* <Link
                to="/products/aisp"
                getProps={isActive}
                className="navbar-item"
              >
                <span>{t('common.product')}s</span>
              </Link> */}
              <Link to="/contact" className="navbar-item">
                <span>{t('pages.contact.contact')}</span>
              </Link>
              <div className="splitter" />

              <div className="header-login-wrapper">
                <a href={state} className="navbar-item">
                  <span>{t('common.login')}</span>
                </a>
                <button>
                  <a href="/register">{t('common.register')}</a>
                </button>
              </div>
            </nav>
          )}
          <LanguageMenu />
        </div>
      </header>
    </>
  )
}

