import React, { useCallback, useState } from 'react';

import 'assets/scss/main.scss';
import Header from 'components/header';
import Menu from 'components/menu';
import Contact from 'components/form/contact';
import Footer from 'components/footer';
import { Toaster } from 'react-hot-toast';
import Policy from 'components/policy';
import { useSession } from '../context/session-context';
import i18n from 'localization';

const Layout = ({ children }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { user, userLoading, loggedIn } = useSession();

  const handleToggleMenu = useCallback((e) => {
    setIsMenuVisible(!isMenuVisible);

    if (e.currentTarget) {
      const href = e.currentTarget
        .getAttribute('href');

      if (href && !(/^\//).test(href)) {
        e.preventDefault();
      }
    }
  }, [isMenuVisible]);

  if(!i18n?.isInitialized) {
    return <div className="preload"><div className="loader" /></div>;
  }

  return (
    <div className={`body ${userLoading ? 'is-loading' : ''} ${isMenuVisible ? 'is-menu-visible' : ''}`}>
      <div id="wrapper">
        <Toaster />

        <Header loggedIn={loggedIn} user={user} className="alt" onToggleMenu={handleToggleMenu} />
        {children}
        <Contact />
        <Footer />
      </div>

      <Menu user={user} onToggleMenu={handleToggleMenu} />
      <Policy />
    </div>
  );
}

export default Layout
