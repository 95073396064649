/* eslint-disable no-inline-comments */

import React from 'react'

export default () => <></>
    // <footer id="footer">
    //     {/* <div className="inner">
    //     <ul className="icons">
    //     <li><a href="#" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
    //     <li><a href="#" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
    //     <li><a href="#" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
    //     <li><a href="#" className="icon alt fa-github"><span className="label">GitHub</span></a></li>
    //     <li><a href="#" className="icon alt fa-linkedin"><span className="label">LinkedIn</span></a></li>
    //     </ul>
    //     </div> */}
    // </footer>
