import React, { useEffect, useState } from 'react';

import Page from './page';

const ProductByEntity = ({ edges }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const [cnt] = edges;
    setContent(cnt);
  }, [edges]);

  return content && content.node
    ? <Page content={content.node} />
    : null;
}

export default ProductByEntity;